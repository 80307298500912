import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import HeroArea from '../components/hero-area'
import CalloutBar from '../components/callout-bar'
import Card from '../components/card'
import styled from 'styled-components'
import FeaturedItems from '../components/featured-items'
import PhotoGalleryComponent from '../components/photo-gallery-component'

import CTA from '../components/cta-bar'
import SocialReviewsFullwidth from '../components/social-reviews-fullwidth'
import parse, { attributesToProps } from 'html-react-parser'
import { TestimonialSection } from '../components/testimonial_video'
import { useTextReplacement } from '../hooks/useTextReplacement'

const MarginTop = styled.div`
  margin-top: -29px;
`
const Intro = styled.div`
  text-align: center;
  padding-top: 30px;
  text-transform: uppercase;
`
const IntroBar = styled.div`
  margin-bottom: 32px;
  padding-bottom: 11px;
`
const GalleryHomePage = styled.div`
  padding: 2.5em 0;
  background: white;
  margin-bottom: 2em;
  text-align: center;
  h2 {
    margin-bottom: 0.35em;
    font-size: calc(0.75vw + 1.2em);
  }
  p {
    max-width: 767px;
    color: #555;
    margin: 0 auto;
    margin-bottom: 1.5em;
  }
  img:nth-child(15) {
    display: none;
  }
`

function isInViewPort(el, topOffSet = 300) {
  var top = el.offsetTop
  var left = el.offsetLeft
  var width = el.offsetWidth
  var height = el.offsetHeight

  while (el.offsetParent) {
    el = el.offsetParent
    top += el.offsetTop - topOffSet
    left += el.offsetLeft
  }

  return (
    top < window.pageYOffset + window.innerHeight &&
    left < window.pageXOffset + window.innerWidth &&
    top + height > window.pageYOffset &&
    left + width > window.pageXOffset
  )
}

const IndexPage = (props) => {
  useTextReplacement()
  const homePageNode = props.data.allContentfulPage.edges[0].node

  const MainContentContainer = styled.div``

  const SidebarContainer = styled.div``

  const SidebarColumn = () => {
    const sideBarCards = homePageNode.sidebar.map((sidebar, index) => (
      <Card key={index} sidebar={sidebar} />
    ))

    return sideBarCards
  }
  useEffect(() => {
    const scrollHandler = function (event) {
      const element = document.getElementById('aboutus')
      if (isInViewPort(element)) {
        console.log('inView', [element], element.classList.add('in-view'))
        // update the element display
      }
    }
    window.addEventListener('scroll', scrollHandler, { passive: true })
    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  })
  const { testimonials: testimonial_video_posts } =
    homePageNode.testimonialSection || { testimonials: [] }

  return (
    <Layout
      isHomePage={true}
      pageProps={props}
      footerLogoShowcase={homePageNode.footerLogoShowcase}
    >
      <SEO
        title={homePageNode.metaTitle}
        description={homePageNode.metaDescription}
        keywords={['gatsby', 'application', 'react']}
      />

      {homePageNode.heroAreaCarousel || homePageNode.heroArea ? (
        <HeroArea
          heroContent={
            homePageNode.heroAreaCarousel
              ? homePageNode.heroAreaCarousel
              : homePageNode.heroArea
          }
        />
      ) : null}
      {homePageNode.calloutBar ? (
        <CalloutBar calloutContent={homePageNode.calloutBar} />
      ) : (
        ''
      )}
      <SocialReviewsFullwidth modalLinkTo={`/reviews/all/`} />
      <div className="shadow-md dotbg">
        <div className="container">
          <IntroBar>
            <Intro>
              <h2> #1 Premier Siding, Windows, Decking & Roofing Company</h2>
            </Intro>

            {homePageNode && homePageNode.html && homePageNode.html.html ? (
              <div
                dangerouslySetInnerHTML={{ __html: homePageNode.html.html }}
              />
            ) : (
              ''
            )}
          </IntroBar>
        </div>
      </div>
      {homePageNode.featuredItems ? (
        <MarginTop>
          <FeaturedItems data={homePageNode.featuredItems} />
        </MarginTop>
      ) : (
        ''
      )}
 <GalleryHomePage className="galleryhomepg">
        {homePageNode &&
          homePageNode.photoGallery &&
          homePageNode.photoGallery[0].photos ? (
          <PhotoGalleryComponent gallery={homePageNode.photoGallery} />
        ) : null}
      </GalleryHomePage>


      {homePageNode && homePageNode.showReputationawardsSection ? (
        <section className="aboutussection shadow-md" id="aboutus">
          <div className="container">
            <div className="row">
              <MainContentContainer
                className={homePageNode.sidebar ? 'col-12' : 'col-12'}
              >
                {homePageNode &&
                homePageNode.featuredContent &&
                homePageNode.featuredContent.featuredContent
                  ? parse(homePageNode.featuredContent.featuredContent, {
                      replace: (domNode) => {
                        if (!domNode.attribs) {
                          return
                        }
                        if (domNode.name !== 'img') return
                        console.log('domNode', domNode, domNode.name !== 'img')

                        const props = attributesToProps(domNode.attribs)
                        console.log('props', props)
                        return <img {...props} loading="lazy" />
                      },
                    })
                  : ''}
              </MainContentContainer>
            </div>
          </div>
        </section>
      ) : null}
      <TestimonialSection testimonial_video_posts={testimonial_video_posts} />
      <div className="container main-content">
        <div className="row">
          <MainContentContainer
            className={homePageNode.sidebar ? 'col-12 col-md-8' : 'col-12'}
            dangerouslySetInnerHTML={{ __html: homePageNode.body.body }}
          />

          {homePageNode.sidebar ? (
            <SidebarContainer className="col-12 col-md-4">
              <SidebarColumn />
            </SidebarContainer>
          ) : (
            ''
          )}
        </div>
      </div>

      <CTA
        title={'Start Your Home Remodeling Project Today!'}
        customTitle={homePageNode.footerCallToActionHeading}
        customDesc={homePageNode.footerCallToActionDesc}
        bgimage={homePageNode.footerCallToActionImg}
        serviceHeroImage={homePageNode.heroImage}
        btn={'Get Started!'}
        props={props}
      />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allContentfulPage(filter: { slug: { eq: "home" } }) {
      edges {
        node {
          featuredItems {
            id
            serviceTitle
            featuredLabel
            excerpt
            shortExcerpt
            slug
            heroImage {
              gatsbyImageData(width: 370)
            }
          }

          id
          slug
          pageTitle
          metaTitle
          metaRobotsContent
          metaDescription
          showReputationawardsSection
          featuredContent {
            featuredContent
          }
          html {
            html
          }
          body {
            body
          }
          footerLogoShowcase
          calloutBar {
            id
            title
            description
            backgroundColor
            textColor
          }
          footerCallToActionHeading
          footerCallToActionDesc
          footerCallToActionImg {
            gatsbyImageData(width: 1800)
          }
          photoGallery {
            id
            title
            description {
              description
            }
            photos {
              id
              title
              fixed(width: 300, height: 250) {
                width
                height
                src
                srcSet
              }
              fluid(maxWidth: 1600) {
                src
              }
            }
          }
          heroArea {
            heroTitle
            description {
              description
            }
            primaryActionBtnLabel
            primaryActionBtnLink
            heroImage {
              gatsbyImageData(width: 1500, placeholder: BLURRED)
            }
          }
          heroAreaCarousel {
            heroTitle
            description {
              description
            }
            primaryActionBtnLabel
            primaryActionBtnLink
            heroImage {
              gatsbyImageData(width: 1400, quality: 70, placeholder: BLURRED)
            }
          }
          testimonialSection {
            testimonials {
              id
              title
              projectReference {
                slug
              }
              video {
                id
                title
                file {
                  url
                  contentType
                }
              }
              thumbnail {
                gatsbyImageData(width: 600, quality: 90, placeholder: NONE)
              }
            }
          }
          sidebar {
            id
            title
            content {
              id
              content
            }
            cssClasses
          }
        }
      }
    }
  }
`
